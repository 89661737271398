(function ($) {
    if (('.main-slider').length > 0) {
        $('.main-slider').slick({
            dots: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 4500,
            speed: 800,
            fade: true,
            cssEase: 'linear',
            infinite: true,
            pauseOnHover: false
        });
    }
})(jQuery);

/**
 * load more for overviews
 */

(function($) {
    $('.js-load-more').each(function(i, e) {
        var stepHolder = '<div class="js-load-more-step row" />',
            loading = false;

        $(this).children('.js-load-more-content').wrapInner($(stepHolder));

        $(e).find('.js-load-more-trigger').on('click', function(evt) {
            evt.preventDefault();
            if (loading) return false;
            loading = true;
            var $base = $(this).parents('.js-load-more'),
                $holder = $base.find('.js-load-more-content'),
                $trigger = $(this);

            console.log($base.data('load-more-url'));

            $holder.addClass('loading');
            $trigger.css('opacity', 0).parent().addClass('loader');
            $.getJSON($base.data('load-more-url'), function(data) {
                $holder.removeClass('loading');
                loading = false;
                $newStep = $(stepHolder);
                $newStep.hide().append($(data.content));
                $holder.append($newStep);
                $newStep.slideDown();

                $trigger.parent().removeClass('loader');

                if (data.hasMorePages) $trigger.css('opacity', 1);
                else $trigger.remove().parent().slideUp();

                $base.data('load-more-url', data.nextPageAjaxURL);
            });
        });
    });
})(jQuery);

/**
 * show reference/case overlay
 */


/*
(function($) {
    $('body').on('click', '.small-case .more', function(e) {
        e.preventDefault();
        var href = $(this).attr('href').replace('#', '');
        var className = '.' + href;
        $(className).fadeIn('slow');
    })
    $('body').on('click', '.close-overlay', function(e) {
        e.preventDefault();
        var caseID = $(this).data('id');
        $('.' + caseID).fadeOut('slow');
    })
})(jQuery);

*/

/**
 * contact from
 */
(function($) {

    //Hide Select Menue if JS is activated and show the editable spans
    $('.module-contactform input, .module-contactform select').not("input[type='submit']").hide();
    $('.module-contactform span').css('display', 'inline-block');
    $('.module-contactform .dropdown').css('display', 'inline-block');

    //Delete Placeholder text on first Click
    $('.module-contactform').on("click", ".placeholder", function() {
        $(this).empty().removeClass('placeholder');
    });

    //Fill Input Fields
    $('span[contenteditable]').on("keyup", function() {
        var input_field = $(this).data("input");
        $("#" + input_field).val($(this).text());
    });

    //Select Menü
    $('.dropdown-item').click(function(e) {
        e.preventDefault();

        var dropdown_text = $(this).text();
        var select_menu = $(this).parents('.dropdown').data('select');

        $('.dropdown-item').removeClass('active');
        $(this).addClass("active");
        $(this).parents('.dropdown').find('.dropdown-toggle').text(dropdown_text + " ");
        $('#' + select_menu).val(dropdown_text);

        //Change Placeholder Text
        var placeholder_text = "";

        if ($(this).attr('id') == 'mail') {
            placeholder_text = 'an meine E-Mail Adresse*';
            $('span[data-input="address"]').text(placeholder_text);
        } else if ($(this).attr('id') == 'phone') {
            placeholder_text = 'unter meiner Telefonnummer*';
            $('span[data-input="address"]').text(placeholder_text);
        } else {

        }

    });

    //Send Contact Form via Ajax
    $('.module-contactform form').on('submit', function(e) {

        e.preventDefault();
        $.post($(this).data('ajax-url'), $(this).serialize(), function(data) {


            if (!data.valid) {

                //show error message
                $errors = $('.module-contactform form').find('.errors');
                $errors.empty();
                $errors.append('<i class="fa fa-exclamation-circle" aria-hidden="true"></i> ' + data.errors_message);

                //mark invalid fields red
                console.log(data);
                $('.module-contactform .invalid').removeClass('invalid');
                for (var i = 0; i < data.errors_fields.length; i++) {
                    $('.module-contactform span[data-input="' + data.errors_fields[i] + '"]').addClass('invalid');
                }

            } else {
                $('.module-contactform').empty().append('<p>Vielen Dank für Ihr Problem! Wir melden uns. Und dann wird losgelöst.</p>');
            }
        });
    });

})(jQuery);

/**
 * sticky navigation
 */
(function($) {
    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 2;
    var navbarHeight = $('#main-nav').outerHeight();

    $(window).scroll(function(event) {
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 400);

    function hasScrolled() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if (Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            $('#main-nav').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
                $('#main-nav').removeClass('nav-up').addClass('nav-down');
            }
        }

        lastScrollTop = st;
    }
})(jQuery);

/**
 * mobile menu
 **/

(function($) {
    $('#nav-icon').click(function() {
        $(this).toggleClass('open');
        $('#navbarCollapse').toggleClass('showMenu');
    });
})(jQuery);

(function($) {
    $('li.nav-main-item').click(function() {
        $(this).toggleClass('sub-open');
    });
})(jQuery);

/**
 * case teaser slide out
 */

(function($) {
    $('.module-workteaserlarge .go-to-case').on('click', function(e) {
        e.preventDefault();
        $('.module-workteaserlarge aside').addClass('open');
        var goTo = this.getAttribute("href");
        setTimeout(function() {
            window.location = goTo;
        }, 1000);
        setTimeout(function() {
            $('.module-workteaserlarge aside').removeClass('open');
        }, 2000);
    })
})(jQuery);

/**
 * Open image in overlay within blog
 */

(function($) {
    $(document).ready(function() {
        $(".single-post .module-wysiwyg img").on("click", function(e) {
            $(this).colorbox({href: $(e.currentTarget).attr("src"), maxWidth: '90%', fixed: true});
        });
    });
})(jQuery);


/**
 * Overlay
 */

(function($) {
    $(document).ready(function() {
        $('.close-icon').on('click', function(e) {
            e.preventDefault();
            $('#overlay').fadeOut('slow');
            Cookies.set('overlay', 'no-show', {expires: 1})
        })
        if (Cookies.get('overlay') !== 'no-show') {
            $('#overlay').show();
        }
    });
})(jQuery);

/**
 * Scroll to Anchor
 */

(function($) {
    $(function() {
        setTimeout(function() {
            if (location.hash) {
                /* we need to scroll to the top of the window first, because the browser will always jump to the anchor first before JavaScript is ready, thanks Stack Overflow: http://stackoverflow.com/a/3659116 */
                window.scrollTo(0, 0);
                target = location.hash.split('#');
                smoothScrollTo($('#' + target[1]));
            }
        }, 1);

        // Select all links with hashes
        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function(event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1000, function() {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) { // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            }
                            ;
                        });
                    }
                }
            });

        function smoothScrollTo(target) {
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
            }
        }
    });
})(jQuery);

jQuery(document).ready(function($) {
    if ($('.mission').length > 0) {
        textAnimation();
    }
});


// Utility function
function Util() {
}

/*
    class manipulation functions
*/
Util.hasClass = function(el, className) {
    return el.classList.contains(className);
};

Util.addClass = function(el, className) {
    var classList = className.split(' ');
    el.classList.add(classList[0]);
    if (classList.length > 1) Util.addClass(el, classList.slice(1).join(' '));
};

Util.removeClass = function(el, className) {
    var classList = className.split(' ');
    el.classList.remove(classList[0]);
    if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(' '));
};

Util.toggleClass = function(el, className, bool) {
    if (bool) Util.addClass(el, className);
    else Util.removeClass(el, className);
};

Util.setAttributes = function(el, attrs) {
    for (var key in attrs) {
        el.setAttribute(key, attrs[key]);
    }
};

/*
  DOM manipulation
*/
Util.getChildrenByClassName = function(el, className) {
    var children = el.children,
        childrenByClass = [];
    for (var i = 0; i < children.length; i++) {
        if (Util.hasClass(children[i], className)) childrenByClass.push(children[i]);
    }
    return childrenByClass;
};

Util.is = function(elem, selector) {
    if (selector.nodeType) {
        return elem === selector;
    }

    var qa = (typeof (selector) === 'string' ? document.querySelectorAll(selector) : selector),
        length = qa.length,
        returnArr = [];

    while (length--) {
        if (qa[length] === elem) {
            return true;
        }
    }

    return false;
};

/*
    Animate height of an element
*/
Util.setHeight = function(start, to, element, duration, cb, timeFunction) {
    var change = to - start,
        currentTime = null;

    var animateHeight = function(timestamp) {
        if (!currentTime) currentTime = timestamp;
        var progress = timestamp - currentTime;
        if (progress > duration) progress = duration;
        var val = parseInt((progress / duration) * change + start);
        if (timeFunction) {
            val = Math[timeFunction](progress, start, to - start, duration);
        }
        element.style.height = val + "px";
        if (progress < duration) {
            window.requestAnimationFrame(animateHeight);
        } else {
            if (cb) cb();
        }
    };

    //set the height of the element before starting animation -> fix bug on Safari
    element.style.height = start + "px";
    window.requestAnimationFrame(animateHeight);
};

/*
    Smooth Scroll
*/

Util.scrollTo = function(final, duration, cb, scrollEl) {
    var element = scrollEl || window;
    var start = element.scrollTop || document.documentElement.scrollTop,
        currentTime = null;

    if (!scrollEl) start = window.scrollY || document.documentElement.scrollTop;

    var animateScroll = function(timestamp) {
        if (!currentTime) currentTime = timestamp;
        var progress = timestamp - currentTime;
        if (progress > duration) progress = duration;
        var val = Math.easeInOutQuad(progress, start, final - start, duration);
        element.scrollTo(0, val);
        if (progress < duration) {
            window.requestAnimationFrame(animateScroll);
        } else {
            cb && cb();
        }
    };

    window.requestAnimationFrame(animateScroll);
};

/*
  Focus utility classes
*/

//Move focus to an element
Util.moveFocus = function(element) {
    if (!element) element = document.getElementsByTagName("body")[0];
    element.focus();
    if (document.activeElement !== element) {
        element.setAttribute('tabindex', '-1');
        element.focus();
    }
};

/*
  Misc
*/

Util.getIndexInArray = function(array, el) {
    return Array.prototype.indexOf.call(array, el);
};

Util.cssSupports = function(property, value) {
    if ('CSS' in window) {
        return CSS.supports(property, value);
    } else {
        var jsProperty = property.replace(/-([a-z])/g, function(g) {
            return g[1].toUpperCase();
        });
        return jsProperty in document.body.style;
    }
};

// merge a set of user options into plugin defaults
// https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/
Util.extend = function() {
    // Variables
    var extended = {};
    var deep = false;
    var i = 0;
    var length = arguments.length;

    // Check if a deep merge
    if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
        deep = arguments[0];
        i++;
    }

    // Merge the object into the extended object
    var merge = function(obj) {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                // If deep merge and property is an object, merge properties
                if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                    extended[prop] = extend(true, extended[prop], obj[prop]);
                } else {
                    extended[prop] = obj[prop];
                }
            }
        }
    };

    // Loop through each object and conduct a merge
    for (; i < length; i++) {
        var obj = arguments[i];
        merge(obj);
    }

    return extended;
};

// Check if Reduced Motion is enabled
Util.osHasReducedMotion = function() {
    if (!window.matchMedia) return false;
    var matchMediaObj = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (matchMediaObj) return matchMediaObj.matches;
    return false; // return false if not supported
};

/*
    Polyfills
*/
//Closest() method
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

//Custom Event() constructor
if (typeof window.CustomEvent !== "function") {

    function CustomEvent(event, params) {
        params = params || {bubbles: false, cancelable: false, detail: undefined};
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
}

/*
    Animation curves
*/
Math.easeInOutQuad = function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};

Math.easeInQuart = function(t, b, c, d) {
    t /= d;
    return c * t * t * t * t + b;
};

Math.easeOutQuart = function(t, b, c, d) {
    t /= d;
    t--;
    return -c * (t * t * t * t - 1) + b;
};

Math.easeInOutQuart = function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t * t * t + b;
    t -= 2;
    return -c / 2 * (t * t * t * t - 2) + b;
};

Math.easeOutElastic = function(t, b, c, d) {
    var s = 1.70158;
    var p = d * 0.7;
    var a = c;
    if (t == 0) return b;
    if ((t /= d) == 1) return b + c;
    if (!p) p = d * .3;
    if (a < Math.abs(c)) {
        a = c;
        var s = p / 4;
    } else var s = p / (2 * Math.PI) * Math.asin(c / a);
    return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b;
};


/* JS Utility Classes */

// make focus ring visible only for keyboard navigation (i.e., tab key)
(function() {
    var focusTab = document.getElementsByClassName('js-tab-focus'),
        shouldInit = false,
        outlineStyle = false,
        eventDetected = false;

    function detectClick() {
        if (focusTab.length > 0) {
            resetFocusStyle(false);
            window.addEventListener('keydown', detectTab);
        }
        window.removeEventListener('mousedown', detectClick);
        outlineStyle = false;
        eventDetected = true;
    };

    function detectTab(event) {
        if (event.keyCode !== 9) return;
        resetFocusStyle(true);
        window.removeEventListener('keydown', detectTab);
        window.addEventListener('mousedown', detectClick);
        outlineStyle = true;
    };

    function resetFocusStyle(bool) {
        var outlineStyle = bool ? '' : 'none';
        for (var i = 0; i < focusTab.length; i++) {
            focusTab[i].style.setProperty('outline', outlineStyle);
        }
    };

    function initFocusTabs() {
        if (shouldInit) {
            if (eventDetected) resetFocusStyle(outlineStyle);
            return;
        }
        shouldInit = focusTab.length > 0;
        window.addEventListener('mousedown', detectClick);
    };

    initFocusTabs();
    window.addEventListener('initFocusTabs', initFocusTabs);
}());

function resetFocusTabsStyle() {
    window.dispatchEvent(new CustomEvent('initFocusTabs'));
}


function textAnimation() {
    // File#: _1_animated-headline
    // Usage: codyhouse.co/license
    var TextAnim = function(element) {
        this.element = element;
        this.wordsWrapper = this.element.getElementsByClassName(' js-text-anim__wrapper');
        this.words = this.element.getElementsByClassName('js-text-anim__word');
        this.selectedWord = 0;
        // interval between two animations
        this.loopInterval = parseFloat(getComputedStyle(this.element).getPropertyValue('--text-anim-pause')) * 1000 || 1000;
        // duration of single animation (e.g., time for a single word to rotate)
        this.transitionDuration = parseFloat(getComputedStyle(this.element).getPropertyValue('--text-anim-duration')) * 1000 || 1000;
        // keep animating after first loop was completed
        this.loop = (this.element.getAttribute('data-loop') && this.element.getAttribute('data-loop') == 'off') ? false : true;
        this.wordInClass = 'text-anim__word--in';
        this.wordOutClass = 'text-anim__word--out';
        // check for specific animations
        this.isClipAnim = Util.hasClass(this.element, 'text-anim--clip');
        if (this.isClipAnim) {
            this.animBorderWidth = parseInt(getComputedStyle(this.element).getPropertyValue('--text-anim-border-width')) || 2;
            this.animPulseClass = 'text-anim__wrapper--pulse';
        }
        initTextAnim(this);
    }

    function initTextAnim(element) {
        // make sure there's a word with the wordInClass
        setSelectedWord(element);
        // if clip animation -> add pulse class
        if (element.isClipAnim) {
            Util.addClass(element.wordsWrapper[0], element.animPulseClass);
        }
        // init loop
        loopWords(element);
    }

    function setSelectedWord(element) {
        var selectedWord = element.element.getElementsByClassName(element.wordInClass);
        if (selectedWord.length == 0) {
            Util.addClass(element.words[0], element.wordInClass);
        } else {
            element.selectedWord = Util.getIndexInArray(element.words, selectedWord[0]);
        }
    }

    function loopWords(element) {
        // stop animation after first loop was completed
        if (!element.loop && element.selectedWord == element.words.length - 1) {
            return;
        }
        var newWordIndex = getNewWordIndex(element);
        setTimeout(function() {
            if (element.isClipAnim) { // clip animation only
                switchClipWords(element, newWordIndex);
            } else {
                switchWords(element, newWordIndex);
            }
        }, element.loopInterval);
    }

    function switchWords(element, newWordIndex) {
        // switch words
        Util.removeClass(element.words[element.selectedWord], element.wordInClass);
        Util.addClass(element.words[element.selectedWord], element.wordOutClass);
        Util.addClass(element.words[newWordIndex], element.wordInClass);
        // reset loop
        resetLoop(element, newWordIndex);
    }

    function resetLoop(element, newIndex) {
        setTimeout(function() {
            // set new selected word
            Util.removeClass(element.words[element.selectedWord], element.wordOutClass);
            element.selectedWord = newIndex;
            loopWords(element); // restart loop
        }, element.transitionDuration);
    }

    function switchClipWords(element, newWordIndex) {
        // clip animation only
        var startWidth = element.words[element.selectedWord].offsetWidth,
            endWidth = element.words[newWordIndex].offsetWidth;

        // remove pulsing animation
        Util.removeClass(element.wordsWrapper[0], element.animPulseClass);
        // close word
        animateWidth(startWidth, element.animBorderWidth, element.wordsWrapper[0], element.transitionDuration, function() {
            // switch words
            Util.removeClass(element.words[element.selectedWord], element.wordInClass);
            Util.addClass(element.words[newWordIndex], element.wordInClass);
            element.selectedWord = newWordIndex;

            // open word
            animateWidth(element.animBorderWidth, endWidth, element.wordsWrapper[0], element.transitionDuration, function() {
                // add pulsing class
                Util.addClass(element.wordsWrapper[0], element.animPulseClass);
                loopWords(element);
            });
        });
    }

    function getNewWordIndex(element) {
        // get index of new word to be shown
        var index = element.selectedWord + 1;
        if (index >= element.words.length) index = 0;
        return index;
    }

    function animateWidth(start, to, element, duration, cb) {
        // animate width of a word for the clip animation
        var currentTime = null;

        var animateProperty = function(timestamp) {
            if (!currentTime) currentTime = timestamp;
            var progress = timestamp - currentTime;

            var val = Math.easeInOutQuart(progress, start, to - start, duration);
            element.style.width = val + "px";
            if (progress < duration) {
                window.requestAnimationFrame(animateProperty);
            } else {
                cb();
            }
        };

        //set the width of the element before starting animation -> fix bug on Safari
        element.style.width = start + "px";
        window.requestAnimationFrame(animateProperty);
    }

    // init TextAnim objects
    var textAnim = document.getElementsByClassName('js-text-anim'),
        reducedMotion = Util.osHasReducedMotion();
    if (textAnim) {
        if (reducedMotion) return;
        for (var i = 0; i < textAnim.length; i++) {
            (function(i) {
                new TextAnim(textAnim[i]);
            })(i);
        }
    }
}

