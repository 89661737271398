var addComment={moveForm:function(a,b,c,d){var e,f,g,h,i=this,j=i.I(a),k=i.I(c),l=i.I("cancel-comment-reply-link"),m=i.I("comment_parent"),n=i.I("comment_post_ID"),o=k.getElementsByTagName("form")[0];if(j&&k&&l&&m&&o){i.respondId=c,d=d||!1,i.I("wp-temp-form-div")||(e=document.createElement("div"),e.id="wp-temp-form-div",e.style.display="none",k.parentNode.insertBefore(e,k)),j.parentNode.insertBefore(k,j.nextSibling),n&&d&&(n.value=d),m.value=b,l.style.display="",l.onclick=function(){var a=addComment,b=a.I("wp-temp-form-div"),c=a.I(a.respondId);if(b&&c)return a.I("comment_parent").value="0",b.parentNode.insertBefore(c,b),b.parentNode.removeChild(b),this.style.display="none",this.onclick=null,!1};try{for(var p=0;p<o.elements.length;p++)if(f=o.elements[p],h=!1,"getComputedStyle"in window?g=window.getComputedStyle(f):document.documentElement.currentStyle&&(g=f.currentStyle),(f.offsetWidth<=0&&f.offsetHeight<=0||"hidden"===g.visibility)&&(h=!0),"hidden"!==f.type&&!f.disabled&&!h){f.focus();break}}catch(q){}return!1}},I:function(a){return document.getElementById(a)}};

jQuery(document).ready(function($) {
    var mobile,
        hashed,
        height_minimized = 0;
    function resize_textarea(element) {
        if ($(element)[0].scrollHeight > $(element).innerHeight()) {
            $(element).stop().clearQueue().animate({height: $(element)[0].scrollHeight}, 500);
        }
    }
    $('.comment-form-comment textarea').keyup(function() { resize_textarea(this); });

    var triggered = false;

    $(window)
        .bind('resize',function(){
            if(triggered) return;
            $('.showComments')
                .remove();
            $('#comment-list')
                .css({
                    height:  'auto',
                })
            mobile = $('#menu_toggle').css('display')=='block';
            hashed = window.location.hash != '';
            hide_comments = $('#comment-list>.comment').length > 3;
            if(!hashed && mobile && hide_comments) {
                triggered = true;
                height_minimized = 0;
                height_maximized = $('#comment-list').height();

                $('#comment-list>.comment')
                    .each(function (i, e) {
                        if (i < 3) {
                            height_minimized += $(e).innerHeight();
                        } else {
                            $(e).css('opacity', 0);
                        }
                    });
                $('#comment-list')
                    .css({
                        height: height_minimized,
                    })
                    .after(
                        $('<a href="javascript:;" class="button showComments">Alle Kommentare anzeigen</a>')
                            .bind('click', function () {
                                $('#comment-list')
                                    .css('height', 'auto')
                                    .children()
                                    .css('opacity', 1);
                                $(this)
                                    .slideUp(function () {
                                        $(this)
                                            .remove()
                                    })
                            })
                    );
            }
        });
    $('.comment-reply-link')
        .bind('click',function(){
            $('.showComments')
                .trigger('click')
        })
    // Frag nicht warum
    setTimeout(function(){
        $(window).trigger('resize')
    },100);

    if($('textarea.field-comment').attr('maxlength')!=undefined) {
        $('textarea.field-comment')
            .bind('keyup', function () {
                $(this).next('.form_info').find('strong').text(parseInt($(this).attr('maxlength')) - parseInt($(this).val().length))
            })
    }
});
